import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import Container from '../../../blocks/Container';
import Pretitle from '../../../elements/Pretitle';
import Title from '../../../elements/Title';
import Text from '../../../elements/Text';
import Image from '../../../elements/Image';

import styles from './ShopifyIntegration.module.css';

const ShopifyIntegration = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/shopify-integration.md/" } }) {
        edges {
          node {
            frontmatter { 
              title
              subtitle
              items
            }
          }
        }
      }
    }
  `);

  const { title, subtitle, items } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.shopifyIntegration}>
      <Container type="big">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column order-xl-1 order-lg-1 order-0">
            <Pretitle className={`${styles.shopifyIntegration__pretitle} d-flex align-items-center`}>
              <div className={styles.shopifyIntegration__icon} />
              Ecommerce
            </Pretitle>
            <Title className={styles.shopifyIntegration__title}>{title}</Title>
            <Text className={styles.shopifyIntegration__text}>
              {subtitle}
              <ul className={`${styles.shopifyIntegration__list}`}>
                {
                  items.map((item, index) => (
                    <li key={index}><span>{item}</span></li>
                  ))
                }
              </ul>
            </Text>
          </div>
          <div className="col-xl-6 col-lg-6 col-12 d-flex order-xl-0 order-lg-0 order-1 justify-content-xl-start justify-content-lg-start justify-content-center">
            <Image name="shopify_integration_example" className={styles.shopifyIntegration__image} />
          </div>
        </div>
      </Container>
    </div>
  )
};

export default ShopifyIntegration;