import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './AutomatedWeekly.module.css';

import Pretitle from '../../../elements/Pretitle';
import Title from '../../../elements/Title';
import Text from '../../../elements/Text';
import Container from '../../../blocks/Container';
import Image from '../../../elements/Image';
import Button from '../../../elements/Button';
import MoreLink from '../MoreLink';

const AutomatedWeekly = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/automated-weekly.md/" } }) {
        edges {
          node {
            frontmatter {
              slidecontent {
                title
                subtitle
                litem {
                  item
                }
              }
            }
          }
        }
      }
    }
  `);

  const { slidecontent } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.automatedWeeklySlide}>
      <Container type="big">
        <Container type="mobile">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12 order-xl-0 order-lg-0 order-1 d-flex justify-content-xl-start justify-content-lg-start justify-content-center">
              <Image name="reporting_window" className={styles.automatedWeeklySlide__image} />
              <Image name="girl_left" className={styles.automatedWeeklySlide__girlLeft} />
            </div>
            {
              slidecontent.map((slide, index) => (
                <div key={index} className="col-xl-6 col-lg-6 col-12 order-xl-1 order-lg-1 order-0">
                  <Pretitle className="d-flex align-items-center">
                    <div className={styles.automatedWeeklySlide__icon} style={{ height: '31px' }} />
                    Reporting
                  </Pretitle>
                  <Title className={`${styles.automatedWeeklySlide__title}`}>{slide.title}</Title>
                  <Text className={`${styles.automatedWeeklySlide__text}`}>
                    {slide.subtitle}
                    <ul className={`${styles.automatedWeeklySlide__list}`}>
                      {
                        slide.litem.map((listing, index) => (
                          <li key={index}><span>{listing.item}</span></li>
                        ))
                      }
                    </ul>
                  </Text>
                  <MoreLink href="/features/reporting" color="blue" />
                </div>
              ))
            }
          </div>
          <div className="d-flex justify-content-center mt-5 mb-5">
            <Button type="action" href="https://start.deadlinefunnel.com/create-monthly">Get started</Button>
          </div>
        </Container>
      </Container>
    </div>
  )
};

export default AutomatedWeekly;