import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './MarketingAutomations.module.css';

import Pretitle from '../../../elements/Pretitle';
import Title from '../../../elements/Title';
import Text from '../../../elements/Text';
import Image from '../../../elements/Image';
import Container from '../../../blocks/Container';
import MoreLink from '../MoreLink';

const MarketingAutomations = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/marketing-automations.md/" } }) {
        edges {
          node {
            frontmatter {
              slidecontent {
                title
                subtitle
                litem {
                  item
                }
              }
            }
          }
        }
      }
    }
  `);

  const { slidecontent } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.marketingAutomationsSlide}>
      <Container type="mobile">
        <Container type="big" className={styles.marketingAutomationsContainer}>
          <div className="row mt-5">
            <div className="col-xl-6 col-lg-6 col-12 order-xl-0 order-lg-0 order-1 d-flex justify-content-xl-start justify-content-lg-start justify-content-center">
              <Image name="blueprint_browser" className={styles.marketingAutomationsSlide__image} />
            </div>
            {
              slidecontent.map((slide, index) => (
                <div key={index} className="col-xl-6 col-lg-6 col-12 order-xl-1 order-lg-1 order-0">
                  <Pretitle>
                    <div className={styles.marketingAutomationsSlide__icon} />
                    Campaigns
                  </Pretitle>
                  <Title className={`${styles.marketingAutomationsSlide__title}`}>{slide.title}</Title>
                  <Text className={`${styles.marketingAutomationsSlide__text}`}>
                    {slide.subtitle}
                    <ul className={`${styles.marketingAutomationsSlide__list}`}>
                      {
                        slide.litem.map((listing, index) => (
                          <li key={index}>{listing.item}</li>
                        ))
                      }
                    </ul>
                  </Text>
                  <MoreLink href="/features/campaigns" color="blue" />
                </div>
              ))
            }
          </div>
        </Container>
      </Container>
    </div>
  )
};

export default MarketingAutomations;