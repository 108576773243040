import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './Portal.module.css';
import Pretitle from '../../../elements/Pretitle';
import Title from '../../../elements/Title';
import Text from '../../../elements/Text';
import Image from '../../../elements/Image';
import Container from '../../../blocks/Container';
import MoreLink from '../MoreLink';

const Portal = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/portal.md/" } }) {
        edges {
          node {
            frontmatter {
              title
              subtitle
              link
              litems
            }
          }
        }
      }
    }
  `);

  const { title, subtitle, link, litems } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.portalSlide}>
      <Container type="big">
        <div className="row">
          <div
            className="col-xl-6 col-lg-6 col-12 order-xl-0 order-lg-0 order-1 d-flex justify-content-xl-start justify-content-lg-start justify-content-center align-items-center">
            <Image name="features/portal_image" className={styles.image}/>
          </div>
          <div className="col-xl-6 col-lg-6 col-12 order-xl-1 order-lg-1 order-0">
            <Pretitle>
              <div className={styles.icon}/>
              Portal
            </Pretitle>
            <Title className={styles.title}>{title}</Title>
            <Text className={styles.text}>
              {subtitle}
              <ul className={styles.list}>
                {litems.map((item, index) => <li key={index}>{item}</li>)}
              </ul>
            </Text>
            <MoreLink href={link} color="blue"/>
          </div>
        </div>
      </Container>
    </div>
  )
};

export default Portal;