import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './FullyCustomizable.module.css';

import Pretitle from '../../../elements/Pretitle';
import Title from '../../../elements/Title';
import Text from '../../../elements/Text';
import Image from '../../../elements/Image';
import Container from '../../../blocks/Container';

import ShopifyIntegration from '../ShopifyIntegration';
import IntegrationsBlock from '../../../blocks/IntegrationsBlock';
import MoreLink from '../MoreLink';
import Portal from '../Portal';

const FullyCustomizable = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/fully-customizable.md/" } }) {
        edges {
          node {
            frontmatter {
              slidecontent {
                title
                subtitle
                litem {
                  item
                }
              }
            }
          }
        }
      }
    }
  `);


  const { slidecontent } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.fullyCustomizableSlide}>
      <Container type="big">
        <Container type="mobile">
          <div className="row mb-5">
            <div className="col-xl-6 col-lg-6 col-12 order-xl-1 order-lg-1 order-1 d-flex justify-content-xl-start justify-content-lg-start justify-content-center">
              <Image name="customizable_bars" className={styles.fullyCustomizableSlide__image} />
            </div>
            {
              slidecontent.map((slide, index) => (
                <div
                  key={index}
                  className="col-xl-6 col-lg-6 col-12 order-xl-0 order-lg-0 order-0"
                >
                  <Pretitle className="d-flex align-items-center">
                    <div className={styles.fullyCustomizableSlide__icon} />
                    People
                  </Pretitle>
                  <Title className={`${styles.fullyCustomizableSlide__title}`}>{slide.title}</Title>
                  <Text className={`${styles.fullyCustomizableSlide__text}`}>
                    {slide.subtitle}
                    <ul className={`${styles.fullyCustomizableSlide__list}`}>
                      {
                        slide.litem.map((listing, index) => (
                          <li key={index}><span>{listing.item}</span></li>
                        ))
                      }
                    </ul>
                  </Text>
                  <MoreLink href="/features/people" color="blue" />
                </div>
              ))
            }
          </div>
          <Portal />
          <div className="relative-position">
            <IntegrationsBlock type="blue" usePretitle={true} />
            <Image name="man_right" className={styles.fullyCustomizableSlide__manRight} />
          </div>
          <ShopifyIntegration />
        </Container>
      </Container>
    </div>
  )
};

export default FullyCustomizable;