import React from 'react';

import styles from './MoreLink.module.css';

const MoreLink = ({ href, color }) => (
  <div className={`${styles.moreLink} ${color === 'green' ? styles.moreLinkGreen : styles.moreLinkBlue}`}>
    <a href={href}>Learn More</a>
  </div>
);

export default MoreLink;
