import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import FeaturesHeader from '../components/slides/features/FeaturesHeader';
import MarketingAutomations from '../components/slides/features/MarketingAutomations';
import FullyCustomizable from '../components/slides/features/FullyCustomizable';
import ManageCampaign from '../components/slides/features/ManageCampaign/ManageCampaign';
import AutomatedWeekly from '../components/slides/features/AutomatedWeekly';

const Features = () => (
  <Layout>
    <SEO
      title="Features | Deadline Funnel"
      description="Create a personal journey for each of your subscribers, and present unique offers to each person through your emails and landing pages."
    />
    <FeaturesHeader />
    <MarketingAutomations />
    <FullyCustomizable />
    <ManageCampaign />
    <AutomatedWeekly />
  </Layout>
);

export default Features;