import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

import styles from './FeaturesHeader.module.css';

import Container from '../../../blocks/Container';
import Title from '../../../elements/Title';
import Text from '../../../elements/Text';
import Image from '../../../elements/Image';
import Button from '../../../elements/Button';
import WistiaEmbed from '../../../elements/WistiaEmbed';

const FeaturesHeader = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/features-header.md/" } }) {
        edges {
          node {
            frontmatter {
              subtitle
            }
          }
        }
      }
    }
  `);

  const { subtitle } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.featuresHeader}>
      <Container type="big" className={`${styles.featuresHeader__container} h-100`}>
        <Container type="mobile">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column justify-content-start on-top">
              <Title className={`${styles.featuresHeader__title}`}>
                Supercharge <br className="mobile-br" />Your Marketing
              </Title>
              <div className="d-flex">
                <Text className={`${styles.featuresHeader__description}`}>
                  {subtitle}
                </Text>
              </div>
              <div className="d-flex justify-content-xl-start justify-content-lg-start justify-content-center align-items-center flex-wrap mt-5">
                <Button
                  type="action"
                  className="mr-xl-3 mr-lg-3 mr-md-3 mr-0 mb-xl-0 mb-lg-0 mb-md-0 mb-2"
                  href="https://start.deadlinefunnel.com/create-monthly"
                  target="_blank"
                >
                  Try It Free
                </Button>
                <div className="m-xl-0 m-lg-0 mt-3 mb-3" style={{ display: 'inline', whiteSpace: 'nowrap' }}>
                  <span className="mr-3">Or</span>
                  <FontAwesomeIcon
                    className={styles.featuresHeader__watchVideoImage}
                    icon={faPlay}
                    style={{ maxWidth: '10px', maxHeight: '10px' }}
                  />
                  <WistiaEmbed hashedId="f1uoi8zj3g">
                    <a className={styles.featuresHeader__link} href="/">Watch Demo Video</a>
                  </WistiaEmbed>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-12 d-flex flex-column justify-content-start on-top">
              <Image name="people_right" className={styles.featuresHeader__rightGroup} />
            </div>
          </div>
          <Image name="plant_dark" className={styles.featuresHeader__plantLeft} />
        </Container>
      </Container>
    </div>
  );
};

export default FeaturesHeader;