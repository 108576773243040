import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import styles from './ManageCampaign.module.css';

import Pretitle from '../../../elements/Pretitle';
import Title from '../../../elements/Title';
import Text from '../../../elements/Text';
import Image from '../../../elements/Image';
import Container from '../../../blocks/Container';
import MoreLink from '../MoreLink';

const ManageCampaign = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/manage-campaign.md/" } }) {
        edges {
          node {
            frontmatter {
              slidecontent {
                title
                subtitle
                litem {
                  item
                }
              }
            }
          }
        }
      }
    }
  `);

  const { slidecontent } = data.allMarkdownRemark.edges[0].node.frontmatter;

  return (
    <div className={styles.manageCampaignSlide}>
      <Container type="big">
        <Container type="mobile">
          <div className="row">
            {
              slidecontent.map((slide, index) => (
                <div key={index} className="col-xl-6 col-lg-6 col-12 d-flex flex-column">
                  <Pretitle className={`${styles.manageCampaignSlide__pretitle} d-flex align-items-center`}>
                    <div className={styles.manageCampaignSlide__icon} />
                    Personalization
                  </Pretitle>
                  <Title className={`${styles.manageCampaignSlide__title}`}>{slide.title}</Title>
                  <Text className={`${styles.manageCampaignSlide__text}`}>
                    {slide.subtitle}
                    <ul className={`${styles.manageCampaignSlide__list}`}>
                      {
                        slide.litem.map((listing, index) => (
                          <li key={index}><span>{listing.item}</span></li>
                        ))
                      }
                    </ul>
                  </Text>
                  <MoreLink href="/features/personalization" color="green" />
                </div>
              ))
            }
            <div className="col-xl-6 col-lg-6 col-12 d-flex justify-content-xl-start justify-content-lg-start justify-content-center">
              <Image name="watercolors" className={styles.manageCampaignSlide__image} />
            </div>
          </div>
        </Container>
      </Container>
    </div>
  )
};

export default ManageCampaign;